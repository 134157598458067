import clsx from 'clsx'
import Link from 'next/link'
import { CSSTransition } from 'react-transition-group'
import { useAcl } from '~/hooks/user'
import { useStore } from '~/stores'
import styles from './SideBar.module.scss'
import NavGroup, { type NavGroupType } from './sideBar/NavGroup'
import { useFeature } from '~/hooks/feature'
import SvgIcon from '~/components/common/SvgIcon'
import WizardBanner from './sideBar/WizardBanner'

export default function SideBar() {
  const store = useStore()
  const acl = useAcl()
  const feature = useFeature()

  const handleLinkClick = () => {
    store.setIsSidebarToggled(false)
  }

  const nav: NavGroupType[] = [
    {
      label: 'Dashboard',
      link: '/dashboard',
      icon: 'home-line'
    }
  ]

  if (acl.can('processes') && !acl.isTenantEntityPerson) {
    nav.push({
      label: 'Portfolio',
      link: '/portfolio',
      icon: 'wallet-02'
    })
  }

  nav.push({
    label: 'Company',
    link: acl.can('company_shares') ? '/company/shares' : '/company/valuations',
    linkAs: '/company',
    icon: 'globe-02-1'
  })

  acl.can('plans') &&
    nav.push({
      label: 'Plans',
      link: '/plans',
      icon: 'layers-three-01'
    })

  const processes: NavGroupType[] = [
    {
      label: 'Awards',
      link: '/awards',
      isVisible: feature.has('award'),
      icon: 'coins-01'
    },
    {
      label: 'Exercise',
      link: '/exercises',
      isVisible: feature.has('exercise'),
      icon: 'route'
    },
    {
      label: 'Termination',
      link: '/terminations',
      isVisible: feature.has('termination'),
      icon: 'star-05'
    },
    {
      label: 'Buyback',
      link: '/buybacks',
      isVisible: feature.has('buyback'),
      icon: 'coins-swap-02'
    },
    {
      label: 'Dividends',
      link: '/dividends',
      isVisible: feature.has('dividendPayout'),
      icon: 'coins-hand'
    }
  ]

  if (acl.isTenantEntityPerson) {
    nav.push({
      label: 'Processes',
      link: '/awards',
      icon: 'check-done-01',
      submenu: processes
    })
  } else if (acl.can('processes')) {
    nav.push(...processes)
  }

  acl.can('manage_participants') &&
    nav.push({
      label: 'People',
      link: '/people',
      icon: 'users-01'
    })

  acl.can('activity_stream') &&
    nav.push({
      label: 'Activity stream',
      link: '/activity-stream',
      icon: 'message-smile-square'
    })

  const footer: NavGroupType[] = [
    {
      label: 'Help & Support',
      link: 'https://intercom.help/eldison-platform/en/',
      target: '_blank',
      icon: 'life-buoy-02'
    }
  ]

  if (acl.can('tenant_settings')) {
    footer.push({
      label: 'Settings',
      link: '/settings/company-profile',
      icon: 'settings-01'
    })
  }

  acl.isSuperAdmin &&
    footer.push({
      label: 'Manage Tenants',
      link: '/admin/tenants',
      icon: 'git-branch-01'
    })

  return (
    <>
      <div
        className={clsx(styles.sidebar, {
          'is-toggled': store.isSidebarToggled
        })}
      >
        <header className="header">
          <Link href="/dashboard">
            <a>
              <SvgIcon height={26} icon="logo" folder="svgs" className="color-primary" />
            </a>
          </Link>
        </header>

        <div className="scroll mx-16 flex-column" onClick={handleLinkClick}>
          {nav.map((item, i) => (
            <NavGroup key={i} {...item} />
          ))}
        </div>

        <div className="mx-16 my-16" onClick={handleLinkClick}>
          {footer.map((item, i) => (
            <NavGroup key={i} {...item} />
          ))}
        </div>

        <WizardBanner />
      </div>

      <CSSTransition
        addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
        in={store.isSidebarToggled}
        classNames="fade"
        unmountOnExit
      >
        <div className={styles.cover}>
          <button className="over-fill" onClick={() => store.setIsSidebarToggled(false)}></button>
        </div>
      </CSSTransition>
    </>
  )
}
