import User, { type Permission, Roles } from '~/models/User'
import { useUserStore } from '~/stores/user'
import logger from '~/services/LoggerService'
import tenant from '~/services/TenantService'
import { useTenant } from '~/hooks/tenant'
import { useCallback, useMemo } from 'react'

export const useUser = () => {
  const user = useUserStore()
  const { tenantApi } = useTenant()
  const acl = useAcl()

  const fetchUser = async () => {
    try {
      const result = await tenantApi.$get('me')

      const data = new User(result)

      user.login(data)

      logger.debug('User', data)
    } catch (e) {
      //
    }
  }

  const activeView = (): Roles => {
    return acl.isManager ? Roles.operator : acl.isApprover ? Roles.approver : Roles.participant
  }

  return {
    user: user.data as User,
    fetchUser,
    activeView
  }
}

export const useAcl = () => {
  const userStore = useUserStore()

  const has = useCallback<(roles: Roles | Roles[]) => boolean>(
    (roles) => {
      roles = Array.isArray(roles) ? roles : [roles]

      const activeRoles = userStore.data?.activeRoles || userStore.data?.roles || []

      return roles.some((role) => activeRoles.includes(role))
    },
    [userStore.data?.roles, userStore.data?.activeRoles]
  )

  const can = useCallback<(permission: Permission) => boolean>(
    (permission: Permission) => {
      if (has(Roles.admin)) {
        return true
      }

      return userStore.data?.permissions.includes(permission) ?? false
    },
    [userStore.data?.permissions]
  )

  const isSuperAdmin = useMemo<boolean>(() => {
    return has(Roles.admin) && tenant.uid === 'eldison'
  }, [has])

  // either operator or admin
  const isManager = useMemo<boolean>(() => has([Roles.admin, Roles.operator]), [has])
  const isApprover = useMemo<boolean>(() => has([Roles.approver]), [has])
  const isTenantEntityPerson = useMemo<boolean>(
    () => isManager || isApprover,
    [isApprover, isManager]
  )

  return {
    can,
    has,
    isManager,
    isApprover,
    isTenantEntityPerson,
    isSuperAdmin
  }
}

export type AclType = ReturnType<typeof useAcl>
